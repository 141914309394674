import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@farewill/ui'

const StyledGridItem = styled(Grid.Item)`
  /* styles for IE11 */
  @media screen and (max-width: 648px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    margin-bottom: 10px;
  }

  /* styles for IE11 */
  @media screen and (min-width: 648px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    flex-basis: 22%;
    -ms-flex-preferred-size: 22%;
  }
`

const GuideCardGrid = ({ children, className }) => (
  <Grid gap="M" gapFromM="L" gapFromL="XL" className={className}>
    {React.Children.map(children, (guideCard) => (
      <StyledGridItem key={guideCard.key} spanFromM={4}>
        {guideCard}
      </StyledGridItem>
    ))}
  </Grid>
)

GuideCardGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

GuideCardGrid.defaultProps = {
  className: undefined,
}

export default GuideCardGrid
