import React from 'react'
import styled from 'styled-components'
import { Wrapper, P, H } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screen } from '@farewill/ui/helpers/responsive'
import GuideCardGrid from 'components/GuideCard/GuideCardGrid'
import GuideCard from 'components/GuideCard'

const StyledGuideCardGrid = styled(GuideCardGrid)`
  ${screen.l`
    > *:nth-child(4) {
      display: none;
    }
  `}

  /* styles for IE11 */
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-content: space-between;
  }
`

interface GuideCardsProps {
  title: string
  description?: string
  guides: GatsbyTypes.ContentfulGuide[]
}

const GuideCards = ({
  title,
  description,
  guides,
}: GuideCardsProps): React.ReactElement => (
  <Wrapper>
    <Wrapper
      separator
      maxWidth={584}
      margin={[0, 0, description ? 'M' : 'XL']}
      marginFromM={['L', 'auto']}
      marginFromL={['XL', 'auto']}
      centeredFromM
    >
      <H size="L" tag="h2" decorative margin={[0, 'auto', 'XS']} centeredFromM>
        {title}
      </H>
      {description && <P color={COLOR.BLACK}>{description}</P>}
    </Wrapper>

    <StyledGuideCardGrid>
      {guides
        .map(
          (guide) =>
            guide.title &&
            guide.image &&
            guide.slug && (
              <GuideCard
                key={guide.slug}
                title={guide.title}
                image={guide.image}
                slug={guide.slug}
              />
            )
        )
        .filter((guide) => !!guide)}
    </StyledGuideCardGrid>
  </Wrapper>
)

export default GuideCards
