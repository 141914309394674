import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, H, P, Image, ArrowRightIcon } from '@farewill/ui'
import { GTR, COLOR, BORDER } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

const StyledH = styled(H)`
  margin-bottom: ${GTR.S};

  ${screenMax.m`
    font-size: ${GTR.M};
  `}
`
const StyledLabel = styled(P)`
  ${smallCaps}
  color: ${COLOR.BLACK};
`

const StyledCard = styled(Link)`
  display: block;
  overflow: hidden;
  height: 100%;
  background: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.M};
  box-shadow: ${BORDER.SHADOW.M};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover ${StyledH} {
    text-decoration: underline;
  }

  &:hover ${StyledLabel} {
    text-decoration: none;
  }

  ${screenMin.l`
    height: 385px;
  `}
`

const StyledIconArrow = styled(ArrowRightIcon)`
  margin-left: ${GTR.XS};
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  min-height: 160px;

  ${screenMin.m`
    flex-direction: column;
  `};

  @media (max-width: 370px) {
    flex-direction: column;
  }
`

const StyledWrapperItemOne = styled(Wrapper)`
  align-self: center;
  justify-self: center;
  position: relative;
  padding: ${GTR.M};

  @media (min-width: 371px) {
    width: 50%;
  }

  ${screenMin.m`
    width: 100%;
  `};
`

const StyledWrapperItemTwo = styled(Wrapper)`
  align-self: center;
  justify-self: center;
  user-select: none;
  padding: 0 0 ${GTR.M};

  @media (min-width: 371px) {
    width: 50%;
    padding: 0;
  }

  ${screenMin.m`
    width: 100%;
  `};
`

const StyledImage = styled(Image)`
  ${screenMin.m`
    margin-bottom: ${GTR.L};
  `};
`

interface GuideCardProps {
  title: string
  image: GatsbyTypes.ContentfulAsset
  slug: string
}

const GuideCard = ({
  title,
  image,
  slug,
}: GuideCardProps): React.ReactElement => (
  <StyledCard to={`/guides/${slug}`}>
    <StyledWrapper>
      <StyledWrapperItemOne>
        <StyledH size="M" tag="h3" decorative>
          {title}
        </StyledH>
        <StyledLabel>
          Read Guide
          <StyledIconArrow size="S" inline />
        </StyledLabel>
      </StyledWrapperItemOne>
      {image && image.file && (
        <StyledWrapperItemTwo>
          <StyledImage
            path={image.file.url}
            formatPath={({ path, width }: { path: string; width: number }) =>
              `${path}?w=${width}&h=${Math.floor(width * 0.45)}&fit=fill`
            }
            width={300}
            widthFromM={400}
            stretch
          />
        </StyledWrapperItemTwo>
      )}
    </StyledWrapper>
  </StyledCard>
)

export default GuideCard
