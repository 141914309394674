import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Wrapper, Grid, H, P, Divider, Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'

import DefaultLayout from 'layouts/DefaultLayout'
import { normalizeArray } from 'lib/graphql/normalize'
import WrapperWithEdge from 'components/WrapperWithEdge'
import PreventOrphan from 'components/PreventOrphan'
import CallToAction from 'components/CallToAction'
import GuideCards from 'components/GuideCards'
import PATHS from 'paths'

import ArticleItem from './ArticleItem'

const StyledGuideLink = styled(Link)`
  ${smallCaps}
  padding: ${GTR.XXS} ${GTR.XS};
`

const StyledHeroP = styled(P)`
  color: ${COLOR.BLACK};
  max-width: 680px;
`

const StyledArticleWrapper = styled.li`
  margin: 0 -${GTR.L};
`

const Guide = ({ data: { guide, otherGuidesData } }) => {
  const { cta, articles } = guide
  const otherGuides = normalizeArray(otherGuidesData)
  return (
    <DefaultLayout
      headerColor={COLOR.ACCENT.PRIMARY}
      title={guide.metaTitle || guide.title}
      description={
        guide?.metaDescription?.metaDescription ||
        guide?.description?.description
      }
    >
      <WrapperWithEdge
        direction="down"
        edgeBackgroundColor={COLOR.BACKGROUND.FOG}
      >
        <Wrapper container>
          <Button.White tag={StyledGuideLink} to={PATHS.GENERAL.CONTENT_HUB}>
            Guide
          </Button.White>
          <H size="XL" tag="h1" decorative margin={[0, 0, 'M']}>
            {guide.title}
          </H>
          {guide.description && (
            <StyledHeroP size="L">
              <PreventOrphan>{guide?.description?.description}</PreventOrphan>
            </StyledHeroP>
          )}
        </Wrapper>
      </WrapperWithEdge>

      <Grid container>
        <Grid.Item spanFromL={8} spanFromXL={7} tag="ol">
          {articles?.map((article, i) => (
            <StyledArticleWrapper key={article.slug}>
              <Divider padding={[0, 'L']} />
              <ArticleItem
                index={i + 1}
                slug={article.slug}
                type={article.type}
                title={article.title}
                description={article?.description?.description}
                image={article.image}
              />
            </StyledArticleWrapper>
          ))}
          <Divider />
        </Grid.Item>

        {cta && (
          <Grid.Item spanFromL={4} startColumnFromL={9}>
            <CallToAction
              title={cta.title}
              htmlDescription={cta?.description?.description}
              buttonText={cta.buttonText}
              buttonLink={cta.buttonLink}
              isTelephoneNumber={cta.isTelephoneNumber}
            />
          </Grid.Item>
        )}

        {!!otherGuides.length && (
          <Grid.Item padding={['S', 0, 0]}>
            <GuideCards title="Other guides" guides={otherGuides} />
          </Grid.Item>
        )}
      </Grid>
    </DefaultLayout>
  )
}

Guide.propTypes = {
  data: PropTypes.shape({
    guide: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string.isRequired,
      }).isRequired,
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.shape({
        metaDescription: PropTypes.string.isRequired,
      }),
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.shape({
            description: PropTypes.string.isRequired,
          }).isRequired,
          image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        })
      ).isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string,
        }).isRequired,
        buttonLink: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        isTelephoneNumber: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    otherGuidesData: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
          }),
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query GuideBySlug($slug: String!) {
    guide: contentfulGuide(slug: { eq: $slug }) {
      title
      description {
        description
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      articles {
        title
        description {
          description
        }
        slug
        type
        image {
          file {
            url
          }
          title
        }
      }
      cta {
        ...CallToActionFields
      }
    }
    otherGuidesData: allContentfulGuide(filter: { slug: { ne: $slug } }) {
      edges {
        node {
          ...GuideCardFields
        }
      }
    }
  }
`

export default Guide
