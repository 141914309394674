import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Wrapper, Image, H, P, ArrowRightIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { getArticlePath } from 'lib/contentful/articles'

const StyledTitle = styled(H)``

const StyledWrapper = styled(Wrapper)`
  display: block;
  position: relative;
  text-decoration: none;

  ${screenMin.m`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  &:hover {
    text-decoration: none;

    ${StyledTitle} {
      text-decoration: underline;
    }
  }
`

const StyledText = styled(Wrapper)`
  ${screenMin.m`
    max-width: 368px;
  `}
`

const StyledPart = styled(H)`
  color: ${COLOR.BLACK};

  svg {
    margin-left: ${GTR.XS};
  }
`

const ArticleItem = ({ index, slug, type, title, description, image }) => (
  <StyledWrapper tag={Link} to={getArticlePath(slug, type)} padding="L">
    <StyledText margin={[0, 0, 'M']} marginFromM={[0, 'M', 0, 0]}>
      <StyledPart context margin={[0, 0, 'S']}>
        Part {index}
        <ArrowRightIcon size="S" inline />
      </StyledPart>
      <StyledTitle size="S" tag="h2">
        {title}
      </StyledTitle>
      <P>{description}</P>
    </StyledText>

    {image && image.file && (
      <Image
        path={image.file.url}
        ext={['webp', 'png']}
        formatPath={({ path, width, ext }) => `${path}?w=${width}&fm=${ext}`}
        width={220}
        stretch
      />
    )}
  </StyledWrapper>
)

ArticleItem.propTypes = {
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Blog', 'Help']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default ArticleItem
